import { render, staticRenderFns } from "./LatestNewsPage.vue?vue&type=template&id=36a4d966&"
import script from "./LatestNewsPage.vue?vue&type=script&lang=js&"
export * from "./LatestNewsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports