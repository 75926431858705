<template>
  <div>
    <row class="wrapper">
      <column
        class="category-widget-container"
        xs="12"
        md="2">
        <slot name="summery"></slot>
      </column>
      <column
        xs="12"
        md="9">
        <row>
          <column xs="12">
            <row
              v-for="(article) in articleFirstBlock"
              :key="article.id">
              <column xs="12">
                <Teaser
                  :grid="[3, 9]"
                  :teaser-element="article"
                  :show-kicker="false"
                  :highlight-by-category="highlightByCategory"
                  image-size="small"
                  teaser-type="medium">
                  <template v-slot:footer>
                    <TeaserDetail
                      :date="new Date(article.publishedAt).toISOString()"
                      style-class="ff-flama fs-sm mt-2"
                      show-publish-prefix/>
                  </template>
                </Teaser>
              </column>
            </row>
          </column>
        </row>
      </column>
    </row>
    <row class="wrapper">
      <column
        xs="12"
        class="mb-3 mt-3 text-center">
        <ad slot-id="panorama"/>
      </column>
    </row>
    <row class="wrapper">
      <column
        xs="12"
        md="12"
        class="mb-3">
        <Ad slot-id="parallax"/>
      </column>
    </row>
    <template v-for="i in orderedAdsList.length">
      <template>
        <row
          :key="i"
          class="wrapper">
          <column
            xs="12"
            md="9"
            offset-md="2">
            <row
              v-for="(article) in restOfTheArticles.slice((i - 1) * adEveryNthStory, ((i - 1) * adEveryNthStory) + adEveryNthStory)"
              :key="article.articleIndex">
              <column xs="12">
                <Teaser
                  :grid="[3, 9]"
                  :teaser-element="article"
                  :show-kicker="false"
                  :highlight-by-category="highlightByCategory"
                  image-size="small"
                  teaser-type="medium">
                  <template v-slot:footer>
                    <TeaserDetail
                      :date="new Date(article.publishedAt).toISOString()"
                      style-class="ff-flama fs-sm mt-2"
                      show-publish-prefix/>
                  </template>
                </Teaser>
              </column>
            </row>
          </column>
        </row>
        <row
          v-if="restOfTheArticles.length >= (((i - 1) * 5) + 1)"
          :key="i+1"
          class="wrapper">
          <column
            xs="12"
            class="mb-3 mt-3 text-center">
            <Ad :slot-id="orderedAdsList[i - 1]"/>
          </column>
        </row>
      </template>
    </template>
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="9">
        <Pagination
          :offset="offset"
          :total="total"
          :size="size"/>
      </column>
    </row>
  </div>
</template>

<script>
import { Page, Pagination } from 'global-components';
import { titleCase } from 'global-utils';

export default {
  name: 'list-page',
  components: {
    Pagination
  },
  extends: Page,
  props: {
    articleList: {
      type: Array,
      required: true
    },
    offset: {
      type: Number,
      default: 0,
      required: true
    },
    total: {
      type: Number,
      default: 0,
      required: true
    },
    size: {
      type: Number,
      default: 0,
      required: true
    },
    adEveryNthStory: {
      type: Number,
      default: 5
    },
  },
  data() {
    return {
      orderedAdsList: ['billboard', 'panoramaSecond', 'billboardSecond'],
      articleFirstBlock: this.articleList.slice(0, this.adEveryNthStory),
      restOfTheArticles: this.articleList.slice(this.adEveryNthStory, this.size),
      highlightByCategory: this.$pubCtx.highlightByCategory
    };
  },
  methods: {
    titleCase
  }
};
</script>
